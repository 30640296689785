<template>
  <ul class="pagination">
    <li>
      <button
        v-if="page !== 1"
        class="pagination__link pagination__link--double pagination__link--double-left"
        type="button"
        @click="$emit('change', 1)"
      >
        <RemixIconComponent category="System" class="pagination__icon" name="arrow-right-double" />
      </button>
    </li>
    <li>
      <button v-if="page !== 1" class="pagination__link" type="button" @click="$emit('change', --page)">
        <RemixIconComponent category="System" class="pagination__icon" name="arrow-drop-left-line" />
      </button>
    </li>
    <li v-for="p in currentPages" :key="p">
      <button
        :class="{ 'pagination__link--active': page === p }"
        class="pagination__link"
        type="button"
        @click="$emit('change', p)"
      >
        {{ p }}
      </button>
    </li>
    <li>
      <button v-if="page !== total" class="pagination__link" type="button" @click="$emit('change', ++page)">
        <RemixIconComponent category="System" class="pagination__icon" name="arrow-drop-right-line" />
      </button>
    </li>
    <li>
      <button
        v-if="page !== total"
        class="pagination__link pagination__link--double"
        type="button"
        @click="$emit('change', total)"
      >
        <RemixIconComponent category="System" class="pagination__icon" name="arrow-right-double" />
      </button>
    </li>
  </ul>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    total: Number,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    currentPages() {
      let startPage, endPage;
      const maxPages = 4;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 860;
  },
  components: {
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.pagination {
  display flex
  justify-content center
  align-items center
  list-style none
  overflow: hidden;
  gap 4px

  &__icon {
    display flex
    align-items center
    justify-content center
    width 24px
    height 24px

    svg path {
      fill var(--gray-100)
    }
  }

  li {
    width 40px
    height 40px
  }

  &__link {
    display flex
    align-items center
    justify-content center
    font-weight normal
    border none
    font-size 0.875em
    line-height 20px
    color var(--gray-100)
    width 100%
    height 100%
    background none
    cursor pointer
    border-radius var(--small_radius)
    transition var(--transition)

    &--disabled {
      cursor: initial;
    }

    &:not(&--disabled):not(&--active):hover {
      background var(--blue-400)
      color var(--body-color-muted)

      & ^[0]__icon svg path {
        fill var(--body-color-muted)
      }
    }

    //&:not(&--disabled):not(&--active):focus {
    //  background var(--blue-500)
    //  color var(--body-color)
    //
    //  & ^[0]__icon svg path {
    //    fill var(--body-color)
    //  }
    //}

    &--active {
      cursor initial
      background var(--blue-300)
      color var(--body-color-muted)
    }

    &--double {
      &-left {
        transform rotate(180deg)
      }

      .pagination__icon {
        width 8px
        height 8px
      }
    }
  }
}
</style>
