<template>
  <div class="shops-page page">
    <div class="shops-page__title-container">
      <h1 class="shops-page__title">Бренды</h1>
    </div>
    <div class="shops-page__container">
      <div ref="shopsList" class="shops-page__content">
        <div class="shops-page__filters">
          <InputComponent
            @input="searchInput"
            v-model.trim="query"
            placeholder="Я ищу..."
            icon-position="left"
          />
          <SelectComponent
            v-model="category"
            :options="categories"
            label-name="title"
            title="Категория"
            @input="load"
          />
        </div>
        <SelectComponent
          v-model="activeSort"
          :clearable="false"
          :searchable="false"
          :options="sortTypes"
          label-name="title"
          title="Сортировать по"
          @input="changeSort"
          class="shops-page__sort-select"
        />
        <div class="sorts">
          <span class="sorts__title">Сортировать по: </span>
          <button
            class="sorts__item"
            type="button"
            v-for="(sort, i) in sortTypes"
            :key="i"
            @click="changeSort(sort)"
            :class="{
              'sorts__item--active': JSON.stringify(activeSort) === JSON.stringify(sort),
            }"
          >
            {{ sort.title }}
          </button>
        </div>
        <div :style="{ opacity: filterLoading ? 0.7 : 1 }" v-if="showShops" class="shops-page__items">
          <router-link
            v-for="(item, i) in shops.data"
            :key="i"
            class="shops-page__item"
            :to="{ name: 'shop', params: { id: item.id } }"
          >
            <ImgComponent class="shops-page__item-img" :head_img="item.head_img" height="290" width="290" />
            <h3 class="shops-page__item-title">{{ item.title }}</h3>
          </router-link>
        </div>
        <span v-else-if="query">По данному запросу ничего не найдено</span>
        <span v-else>Бренды отсутствуют</span>
        <PaginationComponent
          v-if="showPagination"
          :page="page"
          :total="Math.ceil(shops.paginatorInfo.total / first)"
          @change="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import SHOPS_PAGE from "@/graphql/pages/ShopsPage.graphql";
import SelectComponent from "components/inputs/select/index.vue";
import InputComponent from "components/inputs/InputComponent.vue";

let sortTypes = [
  {
    column: "in_popular",
    title: "По алфавиту",
  },
  {
    column: "price",
    title: "По рейтингу продавца",
  },
];

export default {
  name: "ShopsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SHOPS_PAGE,
        variables: {
          first: store.state.isMobile ? 8 : 20,
        },
      })
      .then(({ data }) => {
        store.state.shops_page.shops = data.shops_paginate;
        store.state.shops_page.all_categories = data.all_categories;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  data() {
    return {
      page: 1,
      query: null,
      category: undefined,
      first: this.$store.state.isMobile ? 8 : 20,
      activeSort: sortTypes[0],
      sortTypes: sortTypes,
      filterLoading: false,
      paginateLoading: false,
    };
  },
  computed: {
    categories() {
      return this.$store.state.shops_page.all_categories || [];
    },
    shops() {
      return this.$store.state.shops_page.shops;
    },
    showShops() {
      return this.shops && this.shops.data && this.shops.data.length;
    },
    showPagination() {
      return this.showShops && Math.ceil(this.shops.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    searchInput() {
      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout);
      }
      this.filterLoading = true;
      this.inputDebounceTimeout = setTimeout(() => {
        this.filter();
      }, 500);
    },
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      this.paginateLoading = true;
      await this.load();
    },
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async filter() {
      this.page = 1;
      await this.load();
      this.scrollUp();
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: SHOPS_PAGE,
          variables: {
            page: this.page,
            first: this.first,
            title: this.query,
            category: this.category ? this.category.id : undefined,
          },
        })
        .then(({ data }) => {
          this.filterLoading = false;
          this.paginateLoading = false;
          this.$store.state.shops_page.shops = data.shops_paginate;
          this.$store.state.shops_page.all_categories = data.all_categories;
          this.$store.state.categories = data.categories;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (window.scrollY > this.$refs.shopsList.offsetTop) {
        window.scrollTo({ top: this.$refs.shopsList.offsetTop });
      }
    },
  },
  metaInfo: {
    title: "Бренды",
  },
  components: {
    InputComponent,
    SelectComponent,
    PaginationComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sorts.styl"
.shops-page {
  width 100%
  display grid
  grid-gap 32px
  padding-top 32px
  padding-bottom 48px
  +below(420px) {
    grid-gap 16px
  }

  &__content {
    display grid
    grid-gap 32px
    width 100%
    max-width var(--main_width)
  }

  &__filters {
    display grid
    grid-template-columns 1fr 300px
    grid-gap 30px
    +below(768px) {
      grid-template-columns 1fr
    }
  }

  &__sort-select {
    +above(861px) {
      display none
    }
  }

  &__items {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 24px
    +below(1120px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(620px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display grid
    grid-gap 16px
    padding 8px 8px 16px
    color var(--gray-900)

    &-img {
      width 100%
      height 290px
      object-fit cover
      object-position center
      border-radius: var(--main_radius);
    }

    &-title {
      font-weight: 500;
      font-size: 0.875em
      line-height: 24px;
      margin 0
      text-align center
    }
  }

  &__container {
    display flex
    justify-content center
    padding: 0 15px;
  }

  &__title {
    max-width var(--main_width)
    width 100%
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0 auto

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }
  }
}
</style>
